.footer-details {
  padding: 24px;
  width: 50%;
  font-size: 0.75em;
  h6 {
    color: #5c7080;
    padding-top: 12px;
  }
  color: #8a9ba8;
}
.copyright-text {
  font-size: 0.7em;
  color: #5c7080;
  font-style: italic;
}
.copyright-text {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.footer-logo {
  p {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 0.4em;
    color: #5c7080;
  }
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-self: center;
}
