@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "./personalize.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f8;
}

#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bp3-button-group .bp3-button[class*="bp3-intent-"]:active,
.bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-active,
.bp3-button-group .bp3-button[class*="bp3-intent-"] {
  z-index: 1;
}
