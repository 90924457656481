.logo {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  &.light {
    background-image: url(./assets/logo-inverse.png);
  }

  &.light.banco {
    background-position-y: 12px;
    background-size: 90%;
    background-image: url(./assets/banco.png);
  }

  &.dark {
    background-image: url(./assets/logo_trasp.png);
  }

  &.small {
    width: 75px;
    height: 25px;
  }

  &.medium {
    width: 150px;
    height: 50px;
  }

  &.large {
    width: 250px;
    height: 60px;
  }

  &.auto {
    width: 100%;
    height: 100%;
  }
}
