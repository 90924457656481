

.grid-groups{
    display:grid;

    grid-template-columns: repeat(auto-fill, minmax(120px, 300px));

    .group{
        margin: 10px;
    }
}