.row-profile {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .profile {
        width: 35px;
        height: 35px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(./1.jpg);
        border-radius: 50%;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, .1);
        margin-left: 10px;
    }
}