.card {
}

.bp3-input-group {
}

.bp3-navbar {
  background-color: #222034;
}

.button-nav {
  margin-left: 10px;
  outline: none;
  &:hover {
    .bp3-icon {
      color: white;
    }
  }

  .bp3-button-text {
    color: #fff;
  }
}
